import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';

async function createImg(name) {
    let svg = await createAvatar(style, {
        seed: name,
    });
    return svg
}

export default createImg
<template>
  <v-container>
      <v-col :cols=breakpoints class="mx-auto">
      <h1 class="mb-5">Accountdaten ändern</h1>
      <v-row>
          <v-col cols="6" md="6" sm="6">
            <div style="width: 150px; height: 150px; border-radius: 200px; border: 2px solid #efefef; overflow: hidden; display: flex; justify-content: center; align-items: center" class="mx-auto my-4 img" v-html="svgImg"></div>
          </v-col>
            <v-col cols="2" md="2" sm="2" style="display: flex; justify-content: center; align-items: center">
                <v-btn 
                    rounded
                    fab
                    color="primary"
                    @click="createAvatarImg()"
                >
                  <v-icon>mdi-rotate-right</v-icon>
                </v-btn>
            </v-col>
      </v-row>
      <v-text-field
      label="Name"
      v-model= name
      outlined
      ></v-text-field>
      <v-text-field
      label="Email"
      v-model= email
      outlined
      ></v-text-field>
      <v-text-field
      label="Neues Passwort"
      v-model= password
      outlined
      type="password"
      ></v-text-field>
      <v-text-field
      label="Neues Passwort bestätigen"
      v-model= secondPassword
      outlined
      type="password"
      ></v-text-field>
     <v-checkbox v-model="notification" label="Ich möchte über die Aufgaben per Email Benachrichtigt werden"></v-checkbox>
      <v-btn
      color="primary"
      :loading=loading
      x-large
      @click="ChangeUserData">
      Accountdaten ändern</v-btn>
      </v-col>
  </v-container>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import createImg from '../helpers/createAvatar'

export default {
    data: () => {
        return {
            name: "",
            email: "",
            password: "",
            secondPassword: "",
            notification: true,
            loading: false,
            svgImg: "",
        }
    },
    computed: {
        /* eslint-disable */ 
      breakpoints () { 
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
      },
    },
    methods: {
        makeid() {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 5; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        },

        async createAvatarImg() {
            let id = localStorage.getItem('UID')
            let name = await this.makeid();
            await createImg(name).then(res => {
                this.svgImg = res
            })
            localStorage.setItem('UserImg', this.svgImg)
            axios
            .patch('https://kjg-api.rezept-zettel.de/api/auth/image/' + id, {
                userImg: this.svgImg
            })
            .then(res => {
                console.log(res.data)
            })
            .catch((e) => {
                console.log(e)
            })
        },
        fetchUserData() {
            let id = localStorage.getItem('UID')
            axios
            .get('https://kjg-api.rezept-zettel.de/api/auth/' + id)
            .then(Response => {
                let data = Response.data
                this.email = data.email,
                this.svgImg = data.userImg
                this.name = data.name
                this.notification = data.emailNotification
            })
        },
        ChangeUserData() {            
            let id = localStorage.getItem('UID')
            axios
            .patch('https://kjg-api.rezept-zettel.de/api/auth/' + id, {
                name: this.name,
                email: this.email,
                password: this.password,
                emailNotification: this.notification
            })
            .then(Response => {
                console.log(Response)
                Vue.$toast.open({
                  message: 'Accountdaten wurden erfolgreich geändert',
                  type: 'success',
              });
                
            })
            .catch((err) => {
                console.log(err)
                Vue.$toast.open({
                  message: 'Versuche es später erneut',
                  type: 'error',
              });
            })
        }
    },
    created() {
        this.fetchUserData()
    }
}
</script>

<style>
.img svg {
    width: 100px;
    height: 100px;
}
</style>